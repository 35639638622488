import { Component, inject, OnInit } from '@angular/core';
import { LoadingComponent } from '../../components/loading/loading.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ITurma } from '../../models/turma.model';
import { ApiService } from '../../services/api.service';
import { enumToString } from '../../utils/enum-to-string.util';
import {
  ETurmaStatus,
  ETurmaStatusColor,
  getTurmaStatusColor,
} from '../../enums/e-turma-status.enum';
import { MatTableModule } from '@angular/material/table';
import { ChipComponent } from '../../components/chip/chip.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { TurmaService } from '../../services/turma.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import {
  SCHOOL_UNITS,
  SLOT_TIMES,
  STAGES,
  TurmaDialogComponent,
} from '../../components/turma-dialog/turma-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgClass } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { ECargo } from '../../enums/e-cargo.enum';
import { TeacherDashboardComponent } from '../../components/teacher-dashboard/teacher-dashboard.component';
import { getShortName } from '../../models/colaborador.model';
import { BasePaginatedComponent } from '../../abstracts/base-paginated.component';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { MobileQueryService } from '../../services/mobile-query.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ColaboradorService } from '../../services/colaborador.service';
import {
  TableFilterComponent,
  tableFilterOptions,
} from '../../components/table-filter/table-filter.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LoadingComponent,
    MatIconModule,
    MatTableModule,
    ChipComponent,
    RouterModule,
    NotFoundComponent,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
    NgClass,
    TeacherDashboardComponent,
    LoadingScreenComponent,
    MatMenuModule,
    TableFilterComponent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent extends BasePaginatedComponent implements OnInit {
  protected override paginatedService = inject(TurmaService);
  protected colaboradorService = inject(ColaboradorService);
  protected mobileQueryService = inject(MobileQueryService);

  displayedColumns: string[] = [
    'nome',
    'estagio',
    'horario',
    'teacher',
    'unidade',
    'alunos',
    'status',
    'actions',
  ];

  constructor() {
    super();

    if(this.authService.hasRole(ECargo.RH))
      this.router.navigate(['timesheets'])

    if (this.authService.hasRole(ECargo.Professor)){
      this.displayedColumns = this.displayedColumns.map((c) =>
        c == 'teacher' ? 'formato' : c
      );
  
      this.paginatedService.pageSize = 100   
    }

    if(this.mobileQueryService.matches){
      if(this.authService.hasRole([ECargo.Adm, ECargo.Coordenador, ECargo.Comercial])){
        this.displayedColumns = ['nome', 'actions']
      }
      else {
        this.displayedColumns = ['nome', 'horario']
      }
    }

  }

  protected getShortName = getShortName;
  protected enumToString = enumToString;
  protected eTurmaStatus = ETurmaStatus;
  protected eTurmaStatusColor = ETurmaStatusColor;
  protected getTurmaStatusColor = getTurmaStatusColor;
  readonly dialog = inject(MatDialog);

  protected apiService = inject(ApiService);
  protected authService = inject(AuthService);

  edit(turma: ITurma): void {
    this.dialog.open(TurmaDialogComponent, {
      data: turma,
    });
  }

  isTeacher(): boolean {
    return this.authService.hasRole(ECargo.Professor);
  }

  canEdit(): boolean {
    return this.authService.hasRole([
      this.authService.roles.Adm,
      this.authService.roles.Coordenador,
    ]);
  }

  slotTimes: tableFilterOptions = SLOT_TIMES.map((t) => {
    return {
      label: t,
      value: t,
    };
  });

  schoolUnits: tableFilterOptions = SCHOOL_UNITS.map((s) => {
    return {
      value: String(s.id),
      label: String(s.name),
    };
  });

  stages: tableFilterOptions = STAGES.map((s) => {
    return {
      value: String(s.id),
      label: String(s.name),
    };
  });

  classroomStatuses: tableFilterOptions = [
    { value: String(ETurmaStatus.EM_FORMAÇÃO), label: 'Em formação' },
    { value: String(ETurmaStatus.EM_ANDAMENTO), label: 'Em andamento' },
    { value: String(ETurmaStatus.FINALIZADA), label: 'Finalizada' },
  ];

  teachers: tableFilterOptions = [];

  studentsCountFilter: tableFilterOptions = [
    { value: '1_to_3', label: 'Até 3' },
    { value: '4_to_5', label: 'De 4 a 5' },
    { value: '6_or_more', label: '6 ou mais' },
  ];

  ngOnInit(): void {
    this.colaboradorService.getSummary().subscribe((response) => {
      this.teachers = response.items.map((teacher) => {
        return {
          label: getShortName(teacher.name),
          value: teacher.id,
        };
      });
    });
  }
}
