import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  GradeAssignment,
  GradeAssignmentService,
} from '../../services/grade-assignment.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

type dialogDataType = {
  studentId: string;
  stageId: number;
  assignment: GradeAssignment;
};

@Component({
  selector: 'app-grade-assignment-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './grade-assignment-dialog.component.html',
  styleUrl: './grade-assignment-dialog.component.scss',
})
export class GradeAssignmentDialogComponent {
  readonly dialogRef = inject(MatDialogRef<GradeAssignmentDialogComponent>);

  private gradeAssignmentService = inject(GradeAssignmentService);

  readonly data = inject<dialogDataType>(MAT_DIALOG_DATA);

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      grammar: [''],
      speaking: [''],
      quiz: [''],
      dictation: [''],
      callanApp: [''],
    });

    this.form.patchValue(this.data.assignment);
  }

  saveGrade() {
    this.gradeAssignmentService
      .post({
        studentId: this.data.studentId,
        stageId: this.data.stageId,
        grammar: this.form.get('grammar')?.value,
        callanApp: this.form.get('callanApp')?.value,
        quiz: this.form.get('quiz')?.value,
        speaking: this.form.get('speaking')?.value,
        dictation: this.form.get('dictation')?.value,
      })
      .subscribe(() => {
        this.gradeAssignmentService.getAll(this.data.studentId).subscribe();
      });

    this.dialogRef.close();
  }
}
