import { Component, inject, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { turmaFeedback } from '../../components/turma-feedbacks/turma-feedbacks.component';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { NgClass } from '@angular/common';
import { calcularTempoPassado } from '../../utils/tempo-passado.util';
import { getShortName } from '../../models/colaborador.model';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ReactionsBottomSheetComponent } from '../../components/reactions-bottom-sheet/reactions-bottom-sheet.component';


@Component({
  selector: 'app-posts',
  standalone: true,
  imports: [
    ProfilePictureComponent,
    NgClass,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
  ],
  templateUrl: './posts.component.html',
  styleUrl: './posts.component.scss',
})
export class PostsComponent implements OnInit {
  protected apiService = inject(ApiService);
  private route = inject(ActivatedRoute);
  private _bottomSheet = inject(MatBottomSheet);

  getShortName = getShortName;
  calcularTempoPassado = calcularTempoPassado;

  feedbacks: turmaFeedback[] = [];

  ngOnInit(): void {
    this.getComments();
  }

  getComments() {
    const feedback_id = this.route.snapshot.queryParams['view'];

    this.apiService
      .getByFilters<turmaFeedback[]>('classrooms/feedbacks', {
        feedback_id,
      })
      .subscribe((comments) => {
        this.feedbacks = comments;
      });
  }

  reactToFeedback(id?: number) {
    if (!id) return;

    this.feedbacks = this.feedbacks.map((f) => {
      if (f.id && f.id == id) {
        f.reaction_count = (f.reaction_count ?? 0) + (!f.has_reacted ? 1 : -1);
        f.has_reacted = !f.has_reacted;

        if (navigator.vibrate) {
          navigator.vibrate(50);
        }
      }
      return f;
    });

    this.apiService
      .post('classrooms/feedbacks/react', {
        feedback_id: id,
      })
      .subscribe((response: any) => {
        this.feedbacks = this.feedbacks.map((f) => {
          if (f.id && f.id == id) f.reaction_count = response.count;
          return f;
        });
      });
  }

  checkReactions(id?: number){
    this._bottomSheet.open(ReactionsBottomSheetComponent, {
      data: {feedbackId: id}
    });
  }
}
