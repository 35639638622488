import { Component, inject, OnInit } from '@angular/core';
import { BasePaginatedComponent } from '../../abstracts/base-paginated.component';
import { AlunosService, StudentStatistics } from './alunos.service';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { MatTableModule } from '@angular/material/table';
import { NgClass } from '@angular/common';
import { LoadingComponent } from '../../components/loading/loading.component';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { MobileQueryService } from '../../services/mobile-query.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { ChipComponent } from '../../components/chip/chip.component';
import { PieChartComponent } from '../../components/pie-chart/pie-chart.component';
import { MatIconModule } from '@angular/material/icon';
import { BarChartComponent } from '../../components/bar-chart/bar-chart.component';
import {
  TableFilterComponent,
  tableFilterOptions,
} from '../../components/table-filter/table-filter.component';
import { SCHOOL_UNITS } from '../../components/turma-dialog/turma-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-alunos',
  standalone: true,
  imports: [
    LoadingScreenComponent,
    MatTableModule,
    NgClass,
    LoadingComponent,
    ProfilePictureComponent,
    MatPaginatorModule,
    NotFoundComponent,
    RouterModule,
    ChipComponent,
    PieChartComponent,
    BarChartComponent,
    MatIconModule,
    TableFilterComponent,
    MatTooltipModule
  ],
  templateUrl: './alunos.component.html',
  styleUrl: './alunos.component.scss',
})
export class AlunosComponent extends BasePaginatedComponent implements OnInit {
  protected override paginatedService = inject(AlunosService);
  protected mobileQueryService = inject(MobileQueryService);

  desktopColumns: string[] = ['name', 'school', 'classroom', 'status'];

  mobileColumns: string[] = ['name', 'status'];

  statistics?: StudentStatistics;

  studentStatuses: tableFilterOptions = [
    { value: 'active', label: 'Ativo' },
    { value: 'inactive', label: 'Inativo' },
  ];

  schoolUnits: tableFilterOptions = SCHOOL_UNITS.map((s) => {
    return {
      value: String(s.id),
      label: String(s.name),
    };
  });

  ngOnInit(): void {
    this.getStatistics();
  }

  getStatistics(){
    this.statistics = undefined;
    this.paginatedService.getStatistics(this.paginatedService.filters).subscribe((statistics) => {
      this.statistics = statistics;
    });
  }
}
