import { Component, inject, input, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { AuthService } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ChipComponent } from '../chip/chip.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { GradeAssignmentDialogComponent } from '../grade-assignment-dialog/grade-assignment-dialog.component';
import {
  GradeAssignment,
  GradeAssignmentService,
} from '../../services/grade-assignment.service';

@Component({
  selector: 'app-report-card',
  standalone: true,
  imports: [
    MatTableModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ChipComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './report-card.component.html',
  styleUrl: './report-card.component.scss',
})
export class ReportCardComponent implements OnInit {
  protected authService = inject(AuthService);
  protected gradeAssignmentService = inject(GradeAssignmentService);
  readonly dialog = inject(MatDialog);

  studentId = input.required<string>();

  displayedColumns: string[] = [
    'stage',
    'grammar',
    'quiz',
    'dictation',
    'speaking',
    'callanApp',
    'average',
    'attendance',
    'approval',
    'actions',
  ];

  openGradeAssignmentDialog(stageId: number, assignment: GradeAssignment) {
    this.dialog.open(GradeAssignmentDialogComponent, {
      data: {
        studentId: this.studentId(),
        stageId,
        assignment,
      },
    });
  }

  ngOnInit(): void {
    this.gradeAssignmentService.getAll(this.studentId()).subscribe();
  }
}
