import { Component, inject, OnInit } from '@angular/core';
import { ITurma } from '../../models/turma.model';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '../../services/api.service';
import { TurmaService } from '../../services/turma.service';
import { ETurmaStatus } from '../../enums/e-turma-status.enum';
import { ColaboradorService } from '../../services/colaborador.service';
import { AuthService } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';

type option = Record<'id' | 'name', string | number>;

export const SLOT_TIMES: string[] = Array.from(
  { length: 14 },
  (_, i) => `${(i + 7).toString().padStart(2, '0')}:00h`
);

export const SCHOOL_UNITS: option[] = [
  { id: 2, name: 'Pouso Alegre' },
  { id: 3, name: 'Poços de Caldas' },
];

export const STAGES: option[] = Array.from({ length: 12 }, (_, i) => {
  return {
    id: i + 1,
    name: `Stage ${i + 1}`,
  };
});

export const CLASSROOM_FORMATS: option[] = [
  { id: 1, name: 'Online' },
  { id: 2, name: 'Presencial' },
];

@Component({
  selector: 'app-turma-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './turma-dialog.component.html',
  styleUrl: './turma-dialog.component.scss',
})
export class TurmaDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<TurmaDialogComponent>);

  readonly turma? = inject<ITurma>(MAT_DIALOG_DATA);

  protected apiService = inject(ApiService);
  protected authService = inject(AuthService);
  protected colaboradorService = inject(ColaboradorService);

  protected turmaService = inject(TurmaService);

  classroomForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.classroomForm = this.fb.group({
      name: ['', Validators.required],
      unitId: ['', Validators.required],
      formatId: ['', [Validators.required]],
      stageId: ['', [Validators.required]],
      timeslot: ['', [Validators.required]],
      statusId: ['', [Validators.required]],
      teacherId: [''],
      weeklyClasses: ['', Validators.required],
      edition: ['', Validators.required],
    });
  }

  estagios: option[] = STAGES;
  formatos: option[] = CLASSROOM_FORMATS;
  unidades: option[] = SCHOOL_UNITS.filter((school) => {
    if (this.authService.hasRole([this.authService.roles.Adm, this.authService.roles.Comercial])) return true;
    else return school.id == this.authService.currentUser.schoolId;
  });
  teachers: option[] = [];
  horarios: string[] = SLOT_TIMES;

  statuses: option[] = [
    { id: ETurmaStatus.EM_FORMAÇÃO, name: 'Em formação' },
    { id: ETurmaStatus.EM_ANDAMENTO, name: 'Em andamento' },
    { id: ETurmaStatus.FINALIZADA, name: 'Finalizada' },
  ];

  ngOnInit(): void {
    this.colaboradorService
      .getSummary()
      .subscribe((professores) => (this.teachers = professores.items));

    if (this.turma) this.classroomForm.patchValue(this.turma);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close();

    this.turmaService.put({
      id: this.turma?.id,
      ...this.classroomForm.value,
    });
  }
}
