import { Component, signal, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-arrows',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  template: `
    <div>
      <button mat-icon-button [disabled]="!canGoBack()" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!canGoForward()" (click)="goForward()">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  `,
  styles: [],
})
export class NavigationArrowsComponent implements OnInit {
  protected canGoBack = signal(false);
  protected canGoForward = signal(false);
  private history: string[] = [];
  private currentIndex = -1;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Captura a rota inicial ao carregar o componente
    const initialUrl = this.router.url;
    this.history.push(initialUrl);
    this.currentIndex = 0;
    this.updateNavigationState();

    // Monitora mudanças de rota e atualiza o histórico
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.history[this.currentIndex] !== event.urlAfterRedirects) {
        this.history = [...this.history.slice(0, this.currentIndex + 1), event.urlAfterRedirects];
        this.currentIndex = this.history.length - 1;
        this.updateNavigationState();
      }
    });
  }

  private updateNavigationState(): void {
    this.canGoBack.set(this.currentIndex > 0);
    this.canGoForward.set(this.currentIndex < this.history.length - 1);
  }

  goBack(): void {
    if (this.canGoBack()) {
      this.currentIndex--;
      this.router.navigateByUrl(this.history[this.currentIndex]);
      this.updateNavigationState();
    }
  }

  goForward(): void {
    if (this.canGoForward()) {
      this.currentIndex++;
      this.router.navigateByUrl(this.history[this.currentIndex]);
      this.updateNavigationState();
    }
  }

}
