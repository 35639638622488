<table mat-table [dataSource]="dataSource" class="table !shadow-none !cursor-auto">

  <ng-container matColumnDef="stage">
    <th mat-header-cell *matHeaderCellDef> Material </th>
    <td mat-cell *matCellDef="let element"> {{element.stage}} </td>
  </ng-container>

  <ng-container matColumnDef="receivedAt">
    <th mat-header-cell *matHeaderCellDef> Recebido </th>
    <td mat-cell *matCellDef="let material">
      <div class="flex justify-center items-center w-14">
        @if(!material.received || authService.hasRole([authService.roles.Adm])) {
        <mat-checkbox [(ngModel)]="material.received" (change)="checkMaterial($event, material.stageId)"></mat-checkbox>
        }
        @else {
        <mat-icon class="text-blue-500 !m-0">check_box</mat-icon>
        }
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="addedBy">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let material">
      @if(material.received){
      <p class="text-light">
        <mat-icon class="mat-18">info_outline</mat-icon>
        Atualizado por {{material.addedBy ?? authService.currentUser.name }}
        @if(material.receivedAt){
        em {{material.receivedAt}}
        }
        @else {
        há pouco
        }
      </p>
      }
      @else {
      <p>--</p>
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>