function camelToSnakeCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

export function convertObjectToSnakeCase<T extends Record<string, any>>(
  obj: T
): Record<string, any> {
  return Object.keys(obj).reduce((acc, key) => {
    const snakeKey = camelToSnakeCase(key);
    acc[snakeKey] = obj[key];
    return acc;
  }, {} as Record<string, any>);
}
