import { Component, inject, input, model, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { ApiService } from '../../services/api.service';
import { MatIconModule } from '@angular/material/icon';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { AuthService } from '../../services/auth.service';
import { FormsModule } from '@angular/forms';

type StudentMaterial = {
  stage: string;
  receivedAt?: string;
  addedBy: string;
  received?: boolean;
};

const DATA: StudentMaterial[] = [];

@Component({
  selector: 'app-student-material-list',
  standalone: true,
  imports: [MatTableModule, MatIconModule, MatCheckboxModule, FormsModule],
  templateUrl: './student-material-list.component.html',
  styleUrl: './student-material-list.component.scss',
})
export class StudentMaterialListComponent implements OnInit {
  protected apiService = inject(ApiService);
  protected authService = inject(AuthService);

  displayedColumns: string[] = ['stage', 'receivedAt', 'addedBy'];
  dataSource = DATA;

  studentId = input.required<string>();

  receivedMaterials = model<number>(0);

  ngOnInit(): void {
    this.apiService
      .getByFilters<StudentMaterial[]>('students/materials', {
        student_id: this.studentId(),
      })
      .subscribe((materials) => {
        this.dataSource = materials;
        this.countMaterialsReceived();
      });
  }

  checkMaterial(event: MatCheckboxChange, stageId: number): void {
    this.apiService
      .post('students/materials', {
        student_id: this.studentId(),
        stage_id: stageId,
        received: event.checked,
      })
      .subscribe(() => {
        this.countMaterialsReceived();
      });
  }

  countMaterialsReceived() {
    this.receivedMaterials.set(
      this.dataSource.filter((m) => m.received).length
    );
  }
}
