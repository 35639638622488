<!-- Desktop Toolbar -->
<div class="flex flex-row gap-5 py-3 items-center max-md:hidden bg-default">

  <!-- Left Side -->
  <div class="pl-3 md:basis-[var(--sidenav-width)] flex flex-row gap-4 items-center">
    <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="onToogle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a [routerLink]="['/']">
      <app-logomark theme="dark"></app-logomark>
    </a>
  </div>

  <!-- Right Side -->
  <div class="flex flex-row justify-between pr-5 max-md:justify-end" id="right-side">

    <div class="flex flex-row gap-4">
      
      @if(isPWA()){
        <app-navigation-arrows></app-navigation-arrows>
      }
      
      <ng-container *ngTemplateOutlet="searchInput"></ng-container>

    </div>

    <div class="flex items-center gap-4">

      @if (apiService.env == 'dev'){
      <button mat-icon-button [routerLink]="['calendar']">
        <mat-icon class="mat-18">calendar_month</mat-icon>
      </button>
      }

      <button mat-icon-button [matMenuTriggerFor]="notifications" class="!overflow-hidden !rounded-full"
        (click)="notificationService.getAll().subscribe()">
        <ng-container *ngTemplateOutlet="notificationsIcon"></ng-container>
      </button>

      <button [matMenuTriggerFor]="profile">
        <ng-container *ngTemplateOutlet="profilePicture"></ng-container>
      </button>
    </div>

  </div>
</div>

<!-- Mobile Toolbar -->
<div class="flex flex-row md:hidden justify-between px-5 py-4 bg-default">

  @if (showSearchInputOnMobile) {
  <ng-container *ngTemplateOutlet="searchInput; context: {autofocus}"></ng-container>
  }@else {
  <div class="flex flex-row items-center gap-4">
    <button [matMenuTriggerFor]="profile">
      <ng-container *ngTemplateOutlet="profilePicture"></ng-container>
    </button>
    <h1 class="!text-xl text-nowrap">{{firstName()}}</h1>
  </div>
  }

  <div class="flex flex-row items-center">


    @if (!showSearchInputOnMobile) {
    <button mat-icon-button class="!overflow-hidden !rounded-full"
      (click)="showSearchInputOnMobile = true; autofocus = true">
      <mat-icon>search</mat-icon>
    </button>
    }


    @if (apiService.env == 'dev'){
    <button mat-icon-button [routerLink]="['calendar']">
      <mat-icon class="mat-18">calendar_month</mat-icon>
    </button>
    }

    <button mat-icon-button [routerLink]="['notificacoes']" class="!overflow-hidden !rounded-full">
      <ng-container *ngTemplateOutlet="notificationsIcon"></ng-container>
    </button>
    <button mat-icon-button class="!overflow-hidden !rounded-full" (click)="snav().toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>

<ng-template #searchInput let-autofocus="autofocus">
  <mat-form-field appearance="outline" class="small -mb-5 rounded">
    <mat-label>Pesquisar</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput type="text" (keydown.enter)="onSearch()" [appAutoFocus]="autofocus" [(ngModel)]="search">
  </mat-form-field>
</ng-template>

<ng-template #profilePicture>

  <app-profile-picture [user]="authService.currentUser" size="medium" [hideName]="true"></app-profile-picture>

</ng-template>

<!-- Notifications -->
<mat-menu #notifications="matMenu" [xPosition]="'before'">
  <app-notifications></app-notifications>
</mat-menu>

<!-- Profile Shortcuts -->
<mat-menu #profile="matMenu">
  <button mat-menu-item>
    <mat-icon>person</mat-icon>
    <span>{{authService.currentUser.name}}</span>
  </button>

  <button mat-menu-item (click)="openProfilePictureUploadDialog()">
    <mat-icon>photo_camera</mat-icon>
    Foto de perfil
  </button>

  <button mat-menu-item class="!text-red-500" (click)="authService.logout()">
    <mat-icon class="!text-red-500">logout</mat-icon>
    <span>Sair</span>
  </button>
</mat-menu>


<!-- Notifications Icon -->

<ng-template #notificationsIcon>
  @let unreadCount = notificationService.unread();

  @if(unreadCount > 0){
  <mat-icon [matBadge]="unreadCount">notifications_none</mat-icon>
  }
  @else {
  <mat-icon>notifications_none</mat-icon>
  }
</ng-template>