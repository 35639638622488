import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { PessoaFormComponent } from '../../components/pessoa-form/pessoa-form.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilePictureComponent } from '../../components/profile-picture/profile-picture.component';
import { IAluno } from '../../models/aluno.model';
import { bindObjectPropsToCamelCase } from '../../utils/props-to-camel-case.util';
import { NgClass } from '@angular/common';
import { LoadingScreenComponent } from '../../components/loading-screen/loading-screen.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { calcularTempoPassado } from '../../utils/tempo-passado.util';
import { MatTooltipModule } from '@angular/material/tooltip';
import { formatDateTime } from '../../utils/format-date.util';
import { ChipComponent } from '../../components/chip/chip.component';
import { StudentMaterialListComponent } from '../../components/student-material-list/student-material-list.component';
import { ReportCardComponent } from '../../components/report-card/report-card.component';
import { Title } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { OcorrenciaDialogComponent } from '../../components/ocorrencia-dialog/ocorrencia-dialog.component';

type Matricula = {
  student: IAluno;
  responsavel: any;
  classroom?: { id: string; name: string; active: boolean };
};

type Ocorrencia = {
  id: number;
  aluno_id: string;
  classroom_id: string;
  classroom_name: string;
  created_by: string;
  creator_name: string;
  school_name: string;
  created_at: string;
  description: string;
};

@Component({
  selector: 'app-aluno',
  standalone: true,
  imports: [
    PessoaFormComponent,
    MatTabsModule,
    ProfilePictureComponent,
    NgClass,
    LoadingScreenComponent,
    NotFoundComponent,
    MatTooltipModule,
    ChipComponent,
    StudentMaterialListComponent,
    ReportCardComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './aluno.component.html',
  styleUrl: './aluno.component.scss',
})
export class AlunoComponent implements OnInit {
  protected apiService = inject(ApiService);
  @ViewChild('alunoForm', { static: false }) alunoForm!: PessoaFormComponent;
  @ViewChild('respForm', { static: false }) respForm!: PessoaFormComponent;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private titleService = inject(Title);
  readonly dialog = inject(MatDialog);

  protected aluno?: IAluno;
  protected responsavel?: any;
  protected ocorrencias: Ocorrencia[] = [];

  protected receivedMaterials: number = 0;

  protected classroom?: { id: string; name: string; active: boolean };

  protected alunoId: string =this.route.snapshot.params['id'];

  notFound: boolean = false;

  calcularTempoPassado = calcularTempoPassado;
  formatDateTime = formatDateTime;

  ngOnInit(): void {

    this.apiService
      .getByFilters<Matricula>('matricula/', {
        alunoId: this.alunoId,
      })
      .subscribe({
        next: (matricula) => {
          this.classroom = matricula.classroom;

          this.alunoForm.personalInfoForm.patchValue(matricula.student);
          this.alunoForm.personalInfoForm.controls['birthdate'].setValue(
            new Date(matricula.student.birthdate ?? '')
          );
          this.alunoForm.isReadonly = true;

          this.aluno = bindObjectPropsToCamelCase(matricula.student);

          // Set page title to student's name
          if (this.aluno?.name) {
            this.titleService.setTitle(this.aluno.name);
          }

          if (matricula.responsavel) {
            this.respForm.personalInfoForm.patchValue(matricula.responsavel);
            this.respForm.personalInfoForm.controls['birthdate'].setValue(
              new Date(matricula.responsavel.birthdate ?? '')
            );
            this.respForm.isReadonly = true;

            this.responsavel = bindObjectPropsToCamelCase(
              matricula.responsavel
            );
          }

          // Read the query param and set the tab index
          this.route.queryParams.subscribe((params) => {
            const tab = params['tab'];
            this.selectedTabIndex = tab ? +tab : 0; // Default to tab 0 if no param
          });
        },
        error: () => {
          this.notFound = true;
        },
      });

    this.getOccurrences();
  }

  getOccurrences() {
    this.apiService
      .getByFilters<Ocorrencia[]>('students/occurrences', {
        aluno_id: this.alunoId,
      })
      .subscribe((ocorrencias) => {
        this.ocorrencias = ocorrencias;
      });
  }

  selectedTabIndex = 0; // Default to first tab

  onTabChange(index: number) {
    // Update the URL with the selected tab index
    this.router.navigate([], {
      queryParams: { tab: index },
      queryParamsHandling: 'merge', // Keeps other query parameters
    });
  }

  openOcorrenciaModal() {
    this.dialog
      .open(OcorrenciaDialogComponent, {
        width: '500px',
        data: {
          student: this.aluno,
          classroomId: this.classroom?.id,
        },
      })
      .afterClosed()
      .subscribe((addedOccurence) => {
        if (addedOccurence === true) {
          this.getOccurrences();
        }
      });
  }
}
